<template>
  <section class="checkCandidate-index">
    <div class="search-component">
      <div class="title">数据筛选</div>
      <div class="header-container">
        <div class="item">
          <div class="text">年度：</div>
          <el-date-picker
            v-model="table.query.jhksnd"
            value-format="yyyy"
            type="year"
            size="small"
            placeholder="请选择年度"
            @change="changeNd"
          ></el-date-picker>
        </div>
        <div class="item">
          <div class="text">快速搜索：</div>
          <el-input v-model="table.query.keyword" placeholder="请输入关键词" size="small"></el-input>
        </div>
        <el-button type="primary" class="keyword" size="small" @click="search">查询</el-button>
        <el-button size="small" @click="reset">重置</el-button>
      </div>
    </div>
    <div class="main">
      <div class="main-header">
        <div class="router2-name">候选人列表</div>
        <div class="btn">
          <!-- <el-button type="primary" size="small">分类统计</el-button> -->
          <el-button size="small" @click="exportExcel">导出EXCEL</el-button>
        </div>
      </div>
      <div class="table">
        <el-table
          v-loading="table.loading"
          :data="table.data"
          height="100%"
          row-key="id"
          :header-cell-style="{ 'text-align': 'center', 'background-color': '#FAFAFA' }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column type="index" label="序号" :index="indexMath"></el-table-column>
          <el-table-column prop="nd" label="年度"></el-table-column>
          <el-table-column prop="xrdw_name" label="选任单位"></el-table-column>
          <el-table-column prop="sjcx" label="随机抽选数"></el-table-column>
          <el-table-column label="随机抽选资格审查">
            <el-table-column prop="sjcx_tg" label="通过">
              <template slot-scope="scope">
                <span class="blue" @click="peoplelist(scope.row, '1')">{{ scope.row.sjcx_tg }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="sjcx_wtg" label="未通过"></el-table-column>
            <el-table-column prop="sjcx_dcl" label="待处理"></el-table-column>
          </el-table-column>
          <el-table-column prop="grsq" label="个人申请数"></el-table-column>
          <el-table-column label="个人资格审查">
            <el-table-column prop="grsq_tg" label="通过">
              <template slot-scope="scope">
                <span class="blue" @click="peoplelist(scope.row, '2')">{{ scope.row.grsq_tg }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="grsq_wtg" label="未通过"></el-table-column>
            <el-table-column prop="grsq_dcl" label="待处理"></el-table-column>
          </el-table-column>
          <el-table-column prop="zztj" label="组织推荐数"></el-table-column>
          <el-table-column label="组织推荐资格审查">
            <el-table-column prop="zztj_tg" label="通过">
              <template slot-scope="scope">
                <span class="blue" @click="peoplelist(scope.row, '3')">{{ scope.row.zztj_tg }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="zztj_wtg" label="未通过"></el-table-column>
            <el-table-column prop="zztj_dcl" label="待处理"></el-table-column>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="table.query.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="table.query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="table.total"
      ></el-pagination>
    </div>
    <peoplelist ref="peoplelist"></peoplelist>
  </section>
</template>

<script>
import peoplelist from './peoplelist.vue';
import Candidate from '@/api/choose/candidate';
export default {
  name: '',
  props: {},
  data() {
    return {
      type: '随机抽选',
      table: {
        data: [],
        query: {
          keyword: '',
          page: 1,
          size: 10,
          jhksnd: new Date().getFullYear().toString(), //年度
          jhlx: '',
        },
        loading: false,
        total: 0,
        options: {
          sczt: {
            children: [],
          },
          bmfs: {
            children: [],
          },
        },
      },
    };
  },
  components: { peoplelist },
  methods: {
    peoplelist(data, bmfs) {
      this.$refs.peoplelist.toggle(true, { xrjh: data.xrjh_id, bmfs: bmfs });
    },
    exportExcel() {
      this.$store.commit('changeLoading', {
        loading: true,
        title: '正在导出excel,由于数据可能较多，请稍后',
        color: 'rgba(255,255,255,0.8)',
      });
      let { total } = this.table;
      let { jhksnd, jhlx, keyword } = this.table.query;
      Candidate.list_ss({ keyword, jhksnd, jhlx, page: 1, size: total })
        .then((res) => {
          let cou = [
            {
              label: '序号',
              key: 'index',
              rowspan: 2,
            },
            {
              label: '年度',
              key: 'nd',
              rowspan: 2,
            },
            {
              label: '选任单位',
              key: 'xrdw_name',
              length: '120',
              rowspan: 2,
            },
            {
              label: '随机抽选数',
              key: 'sjcx',
              rowspan: 2,
            },
            {
              label: '随机抽选资格审查',
              colspan: 3,
              children: [
                {
                  label: '通过',
                  key: 'sjcx_tg',
                },
                {
                  label: '未通过',
                  key: 'sjcx_wtg',
                },
                {
                  label: '待处理',
                  key: 'sjcx_dcl',
                },
              ],
            },
            {
              label: '个人申请数',
              key: 'grsq',
              rowspan: 2,
            },
            {
              label: '个人申请资格审查',
              colspan: 3,
              children: [
                {
                  label: '通过',
                  key: 'grsq_tg',
                },
                {
                  label: '未通过',
                  key: 'grsq_wtg',
                },
                {
                  label: '待处理',
                  key: 'grsq_dcl',
                },
              ],
            },
            {
              label: '组织推荐数',
              key: 'zztj',
              rowspan: 2,
            },
            {
              label: '组织推荐资格审查',
              colspan: 3,
              children: [
                {
                  label: '通过',
                  key: 'zztj_tg',
                },
                {
                  label: '未通过',
                  key: 'zztj_wtg',
                },
                {
                  label: '待处理',
                  key: 'zztj_dcl',
                },
              ],
            },
          ]
          this.$exportExcel(cou,res.items,`候选人资格审查信息(${res.total}条)`)
          // this.$exportExcel({
          //   name: `候选人资格审查信息(${res.total}条)`,
          //   data: res.items,
          //   header: [
          //     {
          //       label: '序号',
          //       key: 'index',
          //       rowspan: 2,
          //     },
          //     {
          //       label: '年度',
          //       key: 'nd',
          //       rowspan: 2,
          //     },
          //     {
          //       label: '选任单位',
          //       key: 'xrdw_name',
          //       length: '120',
          //       rowspan: 2,
          //     },
          //     {
          //       label: '随机抽选数',
          //       key: 'sjcx',
          //       rowspan: 2,
          //     },
          //     {
          //       label: '随机抽选资格审查',
          //       colspan: 3,
          //       children: [
          //         {
          //           label: '通过',
          //           key: 'sjcx_tg',
          //         },
          //         {
          //           label: '未通过',
          //           key: 'sjcx_wtg',
          //         },
          //         {
          //           label: '待处理',
          //           key: 'sjcx_dcl',
          //         },
          //       ],
          //     },
          //     {
          //       label: '个人申请数',
          //       key: 'grsq',
          //       rowspan: 2,
          //     },
          //     {
          //       label: '个人申请资格审查',
          //       colspan: 3,
          //       children: [
          //         {
          //           label: '通过',
          //           key: 'grsq_tg',
          //         },
          //         {
          //           label: '未通过',
          //           key: 'grsq_wtg',
          //         },
          //         {
          //           label: '待处理',
          //           key: 'grsq_dcl',
          //         },
          //       ],
          //     },
          //     {
          //       label: '组织推荐数',
          //       key: 'zztj',
          //       rowspan: 2,
          //     },
          //     {
          //       label: '组织推荐资格审查',
          //       colspan: 3,
          //       children: [
          //         {
          //           label: '通过',
          //           key: 'zztj_tg',
          //         },
          //         {
          //           label: '未通过',
          //           key: 'zztj_wtg',
          //         },
          //         {
          //           label: '待处理',
          //           key: 'zztj_dcl',
          //         },
          //       ],
          //     },
          //   ],
          //   deep: 2,
          // });
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    indexMath(index) {
      let { page, size } = this.table.query;
      return index + 1 + (page - 1) * size;
    },
    // 修改年度
    changeNd() {
      if (this.table.query.jhksnd) {
        return this.getList();
      }
    },
    // 改变审查状态
    changeSczt() {
      if (this.table.query.sczt !== '') {
        return this.getList();
      }
    },
    // 报名方式改变
    changeType(type) {
      if (type === this.type) {
        return;
      }
      this.table.query.bmfs = type;
      return this.getList();
    },
    // 每页条数变化
    handleSizeChange(data) {
      this.table.query.size = data;
      this.page = 1;
      return this.getList();
    },
    // 当前页数变化
    handleCurrentChange(data) {
      if (data === this.table.query.page) {
        return;
      } else {
        this.table.query.page = data;
        return this.getList();
      }
    },
    reset() {
      let { jhlx } = this.table.query;
      this.table.query = {
        page: 1,
        size: 10,
        jhlx,
        keyword: '',
        jhksnd: new Date().getFullYear().toString(),
      };
      return this.getList();
    },
    search() {
      return this.getList();
    },
    getList() {
      this.table.loading = true;
      Candidate.list_ss(this.table.query)
        .then((res) => {
          console.log(res.items);
          this.table.data = res.items;
          this.table.total = res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
  },
  mounted() { },
  created() {
    return this.getList();
  },
  computed: {},
  watch: {
    '$store.getters.active_top_nav': {
      handler: function (n) {
        switch (n.meta.title) {
          case '选任': {
            this.table.query.jhlx = 1;
            break;
          }
          case '增补': {
            this.table.query.jhlx = 2;
            break;
          }
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.checkCandidate-index {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  .header {
    margin-bottom: 20px;
  }
  .search-component {
    background-color: #fff;
    margin-bottom: 20px;
    .title {
      color: $h4;
      border-bottom: 1px solid $border1;
      padding: 15px 30px;
      font-weight: bold;
    }
    .header-container {
      padding: 15px 30px;
      display: flex;
      align-items: center;
      .item {
        display: flex;
        align-items: center;
      }
      .keyword {
        margin-left: 20px;
      }
      .text {
        min-width: 70px;
        font-size: 14px;
        color: #666666;
      }
      .el-input {
        width: 224px;
        margin-right: 10px;
      }
    }
  }
  .main {
    background-color: #fff;
    box-sizing: border-box;
    position: relative;
    flex: 1;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    .main-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      .router2-name {
        color: $h4;
        padding: 15px 0;
        font-weight: bold;
      }
      .el-button {
        margin-right: 20px;
      }
    }

    .table {
      flex: 1;
      padding-bottom: 50px;
      .el-table {
        width: calc(100% - 20px);
        border: 1px solid rgba(232, 232, 232, 1);
        border-bottom: none;
        .header-bgc {
          background-color: $border4;
        }
        .operate {
          width: 100%;
          display: flex;
        }
      }
    }
    .el-pagination {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
</style>
